import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../../components/UserNavbar";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import Select from "react-select";
import "../../assets/css/styles.css";
import Alert from "../../components/Alert";
import { useDispatch } from "react-redux";
import { loginSuccess, logout } from "../../redux/userSlice.js";

const Main = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const Left = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: #0000001f 0px 10px 50px;
`;

const Right = styled.div`
  flex: 4;
  box-sizing: border-box;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.primary};
  gap: 5px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.button};
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Users = () => {
  const [alert, setAlert] = useState({ type: "", message: "" });

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userRole, setUserRole] = useState("");

  const dispatch = useDispatch();

  //fecthing users
  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios.get("/user/all");
      if (res.data.success) {
        setUsers(res.data.data);
      }
    };
    fetchUsers();
  }, []);

  //fill fields on user select
  const handleChange = (e) => {
    const userId = e.value;
    const user = users.find((user) => user._id === userId);
    document.querySelector('#update-form input[type="text"]').value = user.name;
    document.querySelector('#update-form input[type="email"]').value =
      user.email;
    setSelectedUser(user);
  };

  const handleRoleChange = (e) => {
    setUserRole(e.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = document.querySelector(
      '#update-form input[type="text"]'
    ).value;
    const email = document.querySelector(
      '#update-form input[type="email"]'
    ).value;
    const role = userRole;
    const userId = selectedUser._id;
    const data = {
      name,
      email,
      role,
    };
    const res = await axios.put(`/user/update/${userId}`, data);
    if (res.data.success) {
      setAlert({ type: "success", message: res.data.message });
      dispatch(loginSuccess(res.data.data));
    }
  };

  return (
    <Main>
      <Left>
        <Sidebar />
      </Left>
      <Right>
        <Navbar />
        <Form id="select-form">
          <Label>
            Select user to update
            {users && (
              <Select
                options={users.map((user) => {
                  return { value: user._id, label: user.name };
                })}
                className="select"
                onChange={handleChange}
              />
            )}
          </Label>
        </Form>
        <Form id="update-form" onSubmit={handleSubmit}>
          <Label>
            Name
            <Input type="text" />
          </Label>
          <Label>
            Email
            <Input type="email" />
          </Label>
          <Label>Role</Label>
          <Select
            options={[
              { value: "admin", label: "Admin" },
              { value: "editor", label: "Editor" },
            ]}
            className="select"
            onChange={handleRoleChange}
          />
          <Button type="submit">Update</Button>
          {alert.message && <Alert type={alert.type} message={alert.message} />}
        </Form>
      </Right>
    </Main>
  );
};

export default Users;
