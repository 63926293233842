import styled from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import UserRegister from "./pages/UserRegister";
import UserLogin from "./pages/UserLogin";
import UserDashboard from "./pages/dashboard/UserDashboard";
import Users from "./pages/dashboard/Users";
import Vendors from "./pages/dashboard/Vendors";
import Categories from "./pages/dashboard/Categories";
import Tags from "./pages/dashboard/Tags";
import Tax from "./pages/dashboard/Tax";
import Attributes from "./pages/dashboard/Attributes";
import Brands from "./pages/dashboard/Brands";
import "./assets/css/styles.css";
import { FlagsProvider } from "react-feature-flags";
import {modules} from "./modules.js";
import Products from "./pages/dashboard/Products";

const theme = {
  colors: {
    primary: "#0D062D",
    secondary: "#1777CF",
    dark: "#3A3F51",
    light: "#B2B2B2",
    white: "#FFFFFF",
    overlay: "#0d062dbb",
    success: "#66C87B",
    danger: "#ED544E",
    warning: "#F4C700"
  },
  fonts: ["sans-serif", "Inter"],
  fontSizes: {
    primary: "40px",
    secondary: "30px",
    h3: "24px",
    h4: "18px",
    h5: "16px",
    h6: "14px",
    span: "12px",
    text: "16px",
    button: "16px",
    icon: "24px"
  }
};

const Container = styled.div`
  display: flex;
`;

const Main = styled.div``;

const Wrapper = styled.div``;

function App() {
  return (
    <FlagsProvider value={modules}>
      <ThemeProvider theme={theme}>
        <Container>
          <BrowserRouter>
            <Main>
              <Wrapper>
                <Routes>
                  <Route path="/register" element={<UserRegister />} />
                  <Route path="/login" element={<UserLogin />} />
                  <Route path="/" element={<UserDashboard />} />
                  <Route path="/dashboard" element={<UserDashboard />} />
                  <Route path="/dashboard/users" element={<Users />} />
                  <Route path="/dashboard/vendors" element={<Vendors />} />
                  <Route path="/dashboard/categories" element={<Categories />} />
                  <Route path="/dashboard/tags" element={<Tags />} />
                  <Route path="/dashboard/tax" element={<Tax />} />
                  <Route path="/dashboard/attributes" element={<Attributes />} />
                  <Route path="/dashboard/brands" element={<Brands />} />
                  <Route path="/dashboard/products" element={<Products />} />
                </Routes>
              </Wrapper>
            </Main>
          </BrowserRouter>
        </Container>
      </ThemeProvider>
    </FlagsProvider>
  );
}

export default App;