import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/UserNavbar";
import axios from "axios";
import Alert from "../../components/Alert";
import Select from "react-select";
import Table from "../../components/Table";

const Main = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const Left = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: #0000001f 0px 10px 50px;
`;

const Right = styled.div`
  flex: 4;
  box-sizing: border-box;
`;

const H3 = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.h3};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.primary};
  gap: 5px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.button};
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Brands = () => {
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [status, setStatus] = useState("published");
  const [data, setData] = useState([]);

  const columns = [
    { Header: "Image", accessor: "img", Cell: ({ value }) => <img src={value} alt="brand image" style={{ width: "50px", height: "50px" }} /> },
    { Header: "Name", accessor: "name" },
    { Header: "Status", accessor: "status" }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get('/brand/all');
      if(res.data.success){
        const brands = res.data.data.map(brand => {
          return {
            img: brand.img,
            name: brand.name,
            status: brand.status
          }
        });
        setData(brands);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const brand = new FormData();
    brand.append("name", e.target[0].value);
    brand.append("status", status);
    brand.append("img", e.target[1].files[0]);

    const res = await axios.post('/brand/new', brand);

    if (res.data.success) {
      setAlert({ message: res.data.message, type: "success" });
      //clear the form
      e.target[0].value = "";
      e.target[1].value = "";
    } else {
      setAlert({ message: res.data.message, type: "danger" });
    }

  };

  const handleChange = (e) => {
    setStatus(e.value);
  };

  return (
    <Main>
      <Left>
        <Sidebar />
      </Left>
      <Right>
        <Navbar />
        <Form onSubmit={handleSubmit}>
          <H3>Add Brand</H3>
          <Label>
            Brand Name
            <Input type="text" placeholder="Samsung" />
          </Label>
          <Label>
            Brand Image
            <Input type="file" />
          </Label>
          <Label>
            Status
            <Select
              options={[
                { value: "published", label: "Published" },
                { value: "draft", label: "Draft" },
                { value: "private", label: "Private" },
              ]}
              onChange={handleChange}
              className="select"
            />
          </Label>
          <Button type="submit">Add Brand</Button>
          {alert.message && <Alert type={alert.type} message={alert.message} />}
          {data.length > 0 && <Table columns={columns} data={data} />}
        </Form>
      </Right>
    </Main>
  );
};

export default Brands;