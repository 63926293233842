import React from 'react';
import styled from 'styled-components';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/UserNavbar';
import Footer from '../../components/Footer';

const Main = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
`;

const Left = styled.div`
    flex: 1;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: #0000001f 0px 10px 50px;
`;

const Right = styled.div`
    flex: 4;
`;

const UserDashboard = () => {
    return (
        <Main>
            <Left>
                <Sidebar />
            </Left>
            <Right>
                <Navbar />
                <Footer />
            </Right>
        </Main>
    );
};

export default UserDashboard;