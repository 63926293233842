import React from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ProfileIcon from '../assets/images/profile.png';
import { useSelector } from 'react-redux';

const Main = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: #0000001f 0px 10px 50px;
    box-sizing: border-box;
`;

const SearchContainer = styled.div`
    display: flex;
    width: 55%;
    align-items: center;
    gap: 5px;
    justify-content: flex-start;
    border-radius: 25px;
    border: 1px solid ${({ theme }) => theme.colors.light};
    box-sizing: border-box;
    padding: 10px;
    color: ${({ theme }) => theme.colors.light};
`;

const Search = styled.input`
    border: none;
`;

const ProfileContainer = styled.div`
    display: flex;
    width: 45%;
    justify-content: flex-end;
    gap: 25px;
`;

const DateContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: ${({ theme }) => theme.fontSizes.h6};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.dark};
`;

const NotificationContainer = styled.div`
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    border: 1px solid ${({ theme }) => theme.colors.light};
    color: ${({ theme }) => theme.colors.dark};
    font-size: ${({ theme }) => theme.fontSizes.span};
    font-weight: 400;
`;

const Profile = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const Name = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h6};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.dark};
`;

const Role = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.span};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.light};
`;

const ProfileImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
`;

const UserNavbar = () => {

  const user = useSelector((state) => state.user);

  //check if user logged in
  if (!user.currentUser) {
    window.location.href = "/login";
  }

  const currentUser = user.currentUser;

    const date = new Date();

    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
      <Main>
        <SearchContainer>
          <SearchIcon />
          <Search type="text" placeholder="Search..." />
        </SearchContainer>
        <ProfileContainer>
          <DateContainer>
            <CalendarMonthIcon />
            {date.toDateString()}
          </DateContainer>
          <NotificationContainer>
            <NotificationsNoneIcon />
          </NotificationContainer>
          <Profile>
            <NameContainer>
              <Name>{currentUser.name}</Name>
              <Role>{capitalizeFirstLetter(currentUser.role)}</Role>
            </NameContainer>
            <ProfileImage src={currentUser.img ? currentUser.img : ProfileIcon} alt="Profile" />
          </Profile>
        </ProfileContainer>
      </Main>
    );
};

export default UserNavbar;