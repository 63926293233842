import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/UserNavbar";
import axios from "axios";
import Alert from "../../components/Alert";
import Select from "react-select";

const Main = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const Left = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: #0000001f 0px 10px 50px;
`;

const Right = styled.div`
  flex: 4;
  box-sizing: border-box;
`;

const H3 = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.h3};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.primary};
  gap: 5px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.button};
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Tax = () => {
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [status, setStatus] = useState("published");

  const statusOptions = [
    { value: "published", label: "Published" },
    { value: "draft", label: "Draft" },
    {value: "private", label: "Private"}
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tax = {
      name: e.target[0].value,
      rate: e.target[1].value,
      status: status,
    };

    const res = await axios.post("/tax/new", tax);
    if (res.data.success) {
      setAlert({ message: res.data.message, type: "success" });
    } else {
      setAlert({ message: res.data.message, type: "danger" });
    }
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption.value);
  };

  return (
    <Main>
      <Left>
        <Sidebar />
      </Left>
      <Right>
        <Navbar />
        <Form onSubmit={handleSubmit}>
          <H3>Add Tax</H3>
          <Label>
            Tax Name
            <Input type="text" placeholder="VAT" />
          </Label>
          <Label>
            Tax Rate (%)
            <Input type="number" placeholder="15" step={1}/>
          </Label>
          <Label>
            Status
            <Select options={statusOptions} className="select" onChange={handleStatusChange}/>
          </Label>
          <Button type="submit">Add Tax</Button>
          {alert.message && <Alert type={alert.type} message={alert.message} />}
        </Form>
      </Right>
    </Main>
  );
};

export default Tax;