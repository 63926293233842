import React, {useState} from "react";
import styled from "styled-components";
import Logo from "../assets/images/logo.png";
import BgImg from "../assets/images/login-bg.jpg";
import axios from "axios";
import {useSelector} from "react-redux";
import Alert from "../components/Alert";

const Main = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const Left = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
`;

const Right = styled.div`
  flex: 2;
  height: 100%;
  background-image: url(${BgImg});
  background-size: cover;
  background-position: center center;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.overlay};
`;

const LogoImg = styled.img`
  width: 250px;
  margin: 25px 0;
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.primary};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 60%;
  box-sizing: border-box;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.primary};
  gap: 5px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.button};
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const CopyRight = styled.p`
  color: ${(props) => props.theme.colors.dark};
  font-size: ${(props) => props.theme.fontSizes.text};
  position: absolute;
  bottom: 15px;
`;

const UserRegister = () => {

    const [alert, setAlert] = useState({type: "", message: ""});

    //get the user from the redux store
    const user = useSelector(state => state.user);

    //if admin not logged in, redirect to login page
    if(!user.currentUser){
        window.location.href = "/login";
    }
    
    const handleRegister = async (e) => {
        e.preventDefault();
            try{
                //get the form data
                const name = e.target[0].value;
                const email = e.target[1].value;
                const password = e.target[2].value;

                //send the form data to the server
                const res = await axios.post("/user/new", {
                    name: name,
                    email: email,
                    password: password,
                });

                if(res.data.success === true){
                    //clear the form
                    e.target[0].value = "";
                    e.target[1].value = "";
                    e.target[2].value = "";

                    //show the success message
                    setAlert({type: "success", message: res.data.message});
                }else{
                    //show the error message
                    setAlert({type: "danger", message: res.data.message});
                }
            }catch(err){
                console.log(err);
            }
    };

  return (
    <Main>
      <Left>
        <LogoImg src={Logo} alt="Logo" />
        <H1>Register</H1>
        <Form onSubmit={handleRegister}>
          <Label>
            Name
            <Input type="text" placeholder="john@example.com" />
          </Label>
          <Label>
            Email
            <Input type="email" placeholder="John Doe" />
          </Label>
          <Label>
            Password
            <Input type="password" placeholder="********" />
          </Label>
          <Button type="submit">Register</Button>
          {alert.type && <Alert type={alert.type} message={alert.message} />}
        </Form>
        <CopyRight>&copy; 2024 Total Client. All Rights Reserved.</CopyRight>
      </Left>
      <Right>
        <Overlay></Overlay>
      </Right>
    </Main>
  );
};

export default UserRegister;