import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/UserNavbar";
import axios from "axios";
import Alert from "../../components/Alert";
import Select from "react-select";

const Main = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const Left = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: #0000001f 0px 10px 50px;
`;

const Right = styled.div`
  flex: 4;
  box-sizing: border-box;
`;

const H3 = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.h3};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.primary};
  gap: 5px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.button};
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Categories = () => {
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState("published");
  const [parentCategory, setParentCategory] = useState("0");

  //status options
  const options = [
    { value: "published", label: "Published" },
    { value: "draft", label: "Draft" },
    { value: "private", label: "Private"}
  ];

  //all categories
  useEffect(() => {
    const fetchCategories = async () => {
        const res = await axios.get("/category/all");
        if(res.data.success){
            setCategories(res.data.data);
        }
    };
    fetchCategories();
    }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const category = {
      name: e.target[0].value,
      description: e.target[2].value,
      status: status,
      parentId: parentCategory
    };

    const res = await axios.post("/category/new", category);
    if(res.data.success){
        setAlert({ message: res.data.message, type: "success" });
    }else{
        setAlert({ message: res.data.message, type: "danger" });
    }

  };

  const handleStatusChanges = (e) => {
    setStatus(e.value);
  };

  const handleParentCategoryChanges = (e) => {
    setParentCategory(e.value);
  };

  return (
    <Main>
      <Left>
        <Sidebar />
      </Left>
      <Right>
        <Navbar />
        <Form onSubmit={handleSubmit}>
          <H3>Add Category</H3>
          <Label>
            Name
            <Input type="text" placeholder="Gaming Computers" />
          </Label>
          <Label>
            Parent Category
            <Select
              options={categories.map((category) => {
                return { value: category._id, label: category.name };
              })}
              className="select"
                onChange={handleParentCategoryChanges}
            />
          </Label>
          <Label>
            Description
            <Textarea placeholder="Best gaming computers for unbeatable prices." />
          </Label>
          <Label>
            Status
            <Select options={options} className="select" onChange={handleStatusChanges}/>
          </Label>
          <Button type="submit">Add Category</Button>
          {alert.message && <Alert type={alert.type} message={alert.message} />}
        </Form>
      </Right>
    </Main>
  );
};

export default Categories;