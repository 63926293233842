import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/UserNavbar";
import axios from "axios";
import Alert from "../../components/Alert";
import Select from "react-select";

const Main = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const Left = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: #0000001f 0px 10px 50px;
`;

const Right = styled.div`
  flex: 4;
  box-sizing: border-box;
`;

const H3 = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.h3};
  width: 100%;
`;

const H4 = styled.h4`
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSizes.h4};
    margin-bottom: 10px;
    width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  width: auto;
  box-sizing: border-box;
  margin: 0 10px;
`;

const FormLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    flex: 5;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    box-sizing: border-box;
    height: 620px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.colors.light};
    }
`;

const FormRight = styled.div`
    display: flex;
    flex: 2;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 20px;
    box-sizing: border-box;
`;

const Div = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: ${({ theme }) => theme.colors.light};
    padding: 20px;
    box-sizing: border-box;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.primary};
  gap: 5px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 10px;
`;

const Attributes = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.button};
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Products = () => {
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [status, setStatus] = useState("published");
  const [productType, setProductType] = useState("simple");
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [tags, setTags] = useState([]);

  const handleTypeChange = (e) => {
    setProductType(e.value);
  }; 

  const handleChange = (e) => {
    setStatus(e.value);
  };

  const brandChange = (e) => {
    setBrands(e);
  };

  const categoryChange = (e) => {
    setCategories(e);
  };

  const vendorChange = (e) => {
    setVendors(e);
  };

  const tagChange = (e) => {
    setTags(e);
  };

  useEffect(() => {
    const SimpleProduct = document.getElementById("simple-product-data");
    if (productType === "simple") {
      SimpleProduct.style.display = "flex";
    } else {
      SimpleProduct.style.display = "none";
    }
  }, [productType]);

  const createSimpleProduct = async (e) =>{
    e.preventDefault();
    const data = {
      name: e.target[0].value,
      description: e.target[1].value,
      content: e.target[2].value,
      productType: productType,
      price: e.target[4].value,
      salePrice: e.target[5].value,
      sku: e.target[6].value,
      quantity: e.target[7].value,
      weight: e.target[8].value,
      dimensions: e.target[9].value.split("x"),
      warranty: e.target[10].value,
      brand: brands.value,
      categories: categories,
      vendors: vendors,
      tags: tags,
      img: e.target[11].files[0],
      gallery: e.target[12].files,
      status: status
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("content", data.content);
    formData.append("productType", data.productType);
    formData.append("price", data.price);
    formData.append("salePrice", data.salePrice);
    formData.append("sku", data.sku);
    formData.append("quantity", data.quantity);
    formData.append("weight", data.weight);
    formData.append("dimensions", data.dimensions);
    formData.append("warranty", data.warranty);
    formData.append("brand", data.brand);
    for(let i = 0; i<data.categories.length; i++){
      formData.append("categories", data.categories[i].value);
    }
    for(let i = 0; i<data.vendors.length; i++){
      formData.append("vendors", data.vendors[i].value);
    }
    for(let i = 0; i<data.tags.length; i++){
      formData.append("tags", data.tags[i].value);
    }
    formData.append("img", data.img);
    for(let i=0; i<data.gallery.length; i++){
      formData.append("gallery", data.gallery[i]);
    }
    formData.append("status", data.status);
    
    const res = await axios.post('/product/new', formData);
    if(res.data.success){
      setAlert({message: res.data.message, type: "success"});
    }else{
      setAlert({message: res.data.message, type: "danger"});
    }

  };

  return (
    <Main>
      <Left>
        <Sidebar />
      </Left>
      <Right>
        <Navbar />
        <Form onSubmit={createSimpleProduct}>
            <FormLeft>
                <H3>Add New Product</H3>
                <Label>
                    Product Name
                    <Input type="text" placeholder="Lenovo thinkpad"/>
                </Label>
                <Label>
                    Short Description
                    <Textarea rows="4" placeholder="Short description about the product"/>
                </Label>
                <Label>
                    Product Description
                    <Textarea rows="8" placeholder="Product description"/>
                </Label>
                <H4>Product Data</H4>
                <Label>
                    Product Type
                    <Select options={[{value: "simple", label: "Simple"}, {value: "variable", label: "Variable"}]} className="select" onChange={handleTypeChange}/>
                </Label>
                <Div id="simple-product-data">
                    <Label>
                        Regular Price
                        <Input type="number" placeholder="1000"/>
                    </Label>
                    <Label>
                        Sale Price
                        <Input type="number" placeholder="800"/>
                    </Label>
                    <Label>
                        SKU
                        <Input type="text" placeholder="SKU123"/>
                    </Label>
                    <Label>
                        Stock Quantity
                        <Input type="number" placeholder="100"/>
                    </Label>
                    <Label>
                        <Attributes>
                            <Label>
                                Weight (kg)
                                <Input type="text" placeholder="1000"/>
                            </Label>
                            <Label>
                                Dimensions (cm)
                                <Input type="text" placeholder="100x100x100 (length x broad x height)"/>
                            </Label>
                            <Label>
                                warranty
                                <Input type="text" placeholder="1 year warranty"/>
                            </Label>
                        </Attributes>
                    </Label>
                </Div>
            </FormLeft>
            <FormRight>
                <Label>
                    Product Image
                    <Input type="file"/>
                </Label>
                <Label>
                    Product Gallery
                    <Input type="file" multiple/>
                </Label>
                <Label>
                    Brand
                    <Select options={[{value: "lenovo", label: "Lenovo"}, {value: "dell", label: "Dell"}]} className="select" onChange={brandChange}/>
                </Label>
                <Label>
                    Product Category
                    <Select options={[{value: "laptop", label: "Laptop"}, {value: "desktop", label: "Desktop"}]} className="select" isMulti={true} onChange={categoryChange}/>
                </Label>
                <Label>
                    Product Tags
                    <Select options={[{value: "laptop", label: "Laptop"}, {value: "desktop", label: "Desktop"}]} className="select" isMulti={true} onChange={tagChange}/>
                </Label>
                <Label>
                    Vendors
                    <Select options={[{value: "lenovo", label: "Lenovo"}, {value: "dell", label: "Dell"}]} className="select" isMulti={true} onChange={vendorChange}/>
                </Label>
                <Label>
                    Product Status
                    <Select
                        options={[
                            { value: "published", label: "Published" },
                            { value: "draft", label: "Draft" },
                            {value: "private", label: "Private"}
                        ]}
                        onChange={handleChange}
                        className="select"
                    />
                </Label>
                <Label>
                    <Button type="submit">Add Product</Button>
                </Label>
                {alert.message && <Alert type={alert.type} message={alert.message} />}
            </FormRight>
        </Form>
      </Right>
    </Main>
  );
};

export default Products;