import React, {useState} from "react";
import styled from "styled-components";
import Logo from "../assets/images/logo.png";
import BgImg from "../assets/images/login-bg.jpg";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginStart, loginSuccess, loginFailure } from "../redux/userSlice.js";
import Alert from "../components/Alert";

const Main = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const Left = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
`;

const Right = styled.div`
  flex: 2;
  height: 100%;
  background-image: url(${BgImg});
  background-size: cover;
  background-position: center center;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.overlay};
`;

const LogoImg = styled.img`
  width: 250px;
  margin: 25px 0;
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.primary};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 60%;
  box-sizing: border-box;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.primary};
  gap: 5px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.button};
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const CopyRight = styled.p`
  color: ${(props) => props.theme.colors.dark};
  font-size: ${(props) => props.theme.fontSizes.text};
  position: absolute;
  bottom: 15px;
`;

const AdminLogin = () => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({ type: "", message: "" });

  //handle form submission
  const handleLogin = async (e) => {
    e.preventDefault();

    //send data to server
    const data = {
      email: e.target[0].value,
      password: e.target[1].value,
    };

    try {
      const response = await axios.post("/auth/user/login", data);

      dispatch(loginStart());
      if (response.data.success) {
        dispatch(loginSuccess(response.data.data));
        setAlert({ type: "success", message: response.data.message });
        window.location.href = "/dashboard";
      } else {
        dispatch(loginFailure(response.data.message));
        setAlert({ type: "danger", message: response.data.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Main>
      <Left>
        <LogoImg src={Logo} alt="Logo" />
        <H1>Login</H1>
        <Form onSubmit={handleLogin}>
          <Label>
            Email
            <Input type="email" placeholder="john@example.com" />
          </Label>
          <Label>
            Password
            <Input type="password" placeholder="********" />
          </Label>
          <Button type="submit">Login</Button>
          {alert.message && <Alert type={alert.type} message={alert.message}/>}
        </Form>
        <CopyRight>&copy; 2024 Total Client. All Rights Reserved.</CopyRight>
      </Left>
      <Right>
        <Overlay></Overlay>
      </Right>
    </Main>
  );
};

export default AdminLogin;