import React, {useState} from 'react';
import styled from 'styled-components';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/UserNavbar';
import axios from 'axios';
import Alert from '../../components/Alert';

const Main = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const Left = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: #0000001f 0px 10px 50px;
`;

const Right = styled.div`
  flex: 4;
  box-sizing: border-box;
`;

const H3 = styled.h3`
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSizes.h3};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.primary};
  gap: 5px;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.button};
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Vendors = () => {

    const [alert, setAlert] = useState({message: '', type: ''});

    const handleSubmit = async (e) => {
        e.preventDefault();
        const vendor = {
            name: e.target[0].value,
            email: e.target[1].value,
            phone: e.target[2].value,
            address: e.target[3].value
        };

        const res = await axios.post('/vendor/new', vendor);
        if(res.data.success){
            setAlert({message: res.data.message, type: 'success'});
        }else{
            setAlert({message: res.data.message, type: 'danger'});
        }

    };

    return(
        <Main>
            <Left>
                <Sidebar />
            </Left>
            <Right>
                <Navbar />
                <Form onSubmit={handleSubmit}>
                    <H3>Add Vendor</H3>
                    <Label>
                        Vendor Name
                        <Input type="text" placeholder='Apple Inc.'/>
                    </Label>
                    <Label>
                        Vendor Email
                        <Input type="email" placeholder='john@example.com'/>
                    </Label>
                    <Label>
                        Vendor Phone
                        <Input type="tel" placeholder='+61-123 4567'/>
                    </Label>
                    <Label>
                        Vendor Address
                        <Input type="text" placeholder='65 dixon road, Rockingham'/>
                    </Label>
                    <Button type="submit">Add Vendor</Button>
                    {alert.message && <Alert type={alert.type} message={alert.message} />}
                </Form>
            </Right>
        </Main>
    );
};

export default Vendors;