import React, { useEffect } from "react";
import styled from "styled-components";
import LogoImg from "../assets/images/logo.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRight from "@mui/icons-material/ChevronRight";
import GridViewIcon from "@mui/icons-material/GridView";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GroupIcon from "@mui/icons-material/Group";
import InventoryIcon from "@mui/icons-material/Inventory";
import Person4Icon from "@mui/icons-material/Person4";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import QueryStattsIcon from "@mui/icons-material/QueryStats";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 10px;
  box-sizing: border-box;
`;

const Logo = styled.img`
  width: 200px;
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  box-sizing: border-box;
`;

const MenuItem = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.h6};
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`;

const Indicator = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0px;
`;

const SubMenu = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: max-content;
  position: absolute;
  top: 0;
  left: 128%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: #0000001f 0px 10px 50px;
  padding: 10px;
`;

const Sidebar = () => {
  const handleSubMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    //remove class show from all nodes and hide all visble submenus
    document.querySelectorAll(".show").forEach((el) => {
      el.classList.remove("show");
      el.style.display = "none";
    });

    //add or remove class to show/hide to second node
    if (e.target.nextElementSibling.classList.contains("show")) {
      e.target.nextElementSibling.classList.remove("show");
    } else {
      e.target.nextElementSibling.classList.add("show");
    }

    //if contains class show, show submenu
    if (e.target.nextElementSibling.classList.contains("show")) {
      e.target.nextElementSibling.style.display = "flex";
    } else {
      e.target.nextElementSibling.style.display = "none";
    }
  };

  //when user click elsewhere, hide submenu
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target.tagName !== "BUTTON") {
        document.querySelectorAll(".show").forEach((el) => {
          el.classList.remove("show");
          el.style.display = "none";
        });
      }
    });
  }, [document]);

  return (
    <Main>
      <Logo src={LogoImg} alt="Total" />
      <Menu>
        <MenuItem>
          <GridViewIcon />
          Dashboard
        </MenuItem>
        <MenuItem>
          <AccountBalanceIcon />
          Finance Management
        </MenuItem>
        <MenuItem>
          <GroupIcon />
          Customer Management
        </MenuItem>
        <MenuItem>
          <InventoryIcon />
          Inventory Management
        </MenuItem>
        <MenuItem>
          <Person4Icon />
          Employee Management
        </MenuItem>
        <MenuItem>
          <PeopleAltIcon />
          Vendor Management
        </MenuItem>
        <MenuItem>
          <PointOfSaleIcon />
          Sales & POS
        </MenuItem>
        <MenuItem>
          <QueryStattsIcon />
          Reporting & Analytics
        </MenuItem>
        <MenuItem>
          <SettingsIcon />
          System Settings
        </MenuItem>
        <MenuItem>
          <HelpOutlineIcon />
          Help & Support
        </MenuItem>
      </Menu>
    </Main>
  );
};

export default Sidebar;
